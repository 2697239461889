import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { storageConstants } from '@constants/storage.constant';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@shared/services/auth.service';
import { NotificationService } from '@shared/services/notification.service';
import { Notification } from '@shared/types/notification.type';
import { environment } from 'environments/environment';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { ModalPeriodFieldsEnum } from './notification.const';
import { WebSocketService } from '@shared/services/websocket.service';
import { NgIf, NgFor, NgClass, UpperCasePipe, SlicePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProgressBar } from 'primeng/progressbar';
import { ScrollPanel } from 'primeng/scrollpanel';
import { NotificationPeriodComponent } from './notification-period/notification-period.component';
@Component({
    host: {
        '(document:click)': 'onClick($event)'
    },
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    imports: [NgIf, FormsModule, ProgressBar, ScrollPanel, NgFor, NgClass, NotificationPeriodComponent, TranslateModule, UpperCasePipe, SlicePipe]
})
export class NotificationComponent implements OnInit {
  public notifications: Notification[] = [];
  public loading = true;
  public progressing = false;
  public idArea;
  isActive: boolean;

  webSocketEndpoint = environment.baseUrlWithoutApiPrefix + `/ws`;
  stompClient: any;
  topic = '/users/queue/messages';
  token = sessionStorage.getItem('encodedAccessToken');
  public dataPeriodEnum = ModalPeriodFieldsEnum;

  constructor(
    private webSocketService: WebSocketService,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private _eref: ElementRef
  ) {}

  public ngOnInit(): void {
    this.webSocketService.setParentComponent(this);
    this.webSocketService.connect();
    this.loadNotifications();
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.isActive = false;
    }
  }

  /**
   * Makes an API call and gets all the notifications which param 'isConfirmed' is false.
   */
  loadNotifications(): void {
    this.loading = true;
    this.idArea = JSON.parse(localStorage.getItem(storageConstants.area)).idArea;
    this.notificationService.getMyNotifications(this.idArea).subscribe(
      resp => {
        this.notifications = resp.filter(notf => notf.inbound != null && notf.outbound != null);
        this.notifications.sort((one, two) => (new Date(one.dateCreation) > new Date(two.dateCreation) ? -1 : 1));
        this.loading = false;
      },
      err => {
        if (err.status !== 403) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.description, sticky: true });
        }
      }
    );
  }

  toggleDropdown() {
    this.isActive = !this.isActive;
  }

  /**
   * Makes an API call and sets the flag 'isConfirmed' to false to all notifications.
   */
  clearAllNotifications(): void {
    this.progressing = true;
    const body = {
      isConfirmed: false
    };
    this.notificationService.clearAllMeNotifications(body).subscribe(
      () => {
        this.messageService.add({
          severity: this.dataPeriodEnum.MessageSeveritySuccess,
          summary: this.dataPeriodEnum.MessageSummaryEmpty,
          detail: this.translate.instant('messages.success.delete_notifications')
        });
        this.notifications = [];
        this.progressing = false;
      },
      () => {
        this.messageService.add({
          severity: this.dataPeriodEnum.MessageSeverityError,
          summary: this.dataPeriodEnum.MessageSummaryError,
          detail: this.translate.instant('messages.error.delete_notifications'),
          sticky: true
        });
        this.progressing = false;
      }
    );
  }

  /**
   * Makes an API call and sets the flag 'isConfirmed' to false.
   * @param idNotification - ID of the notification to confirm.
   */
  clearNotification(idNotification: string): void {
    const body = {
      isConfirmed: false
    };
    this.notificationService.updateNotification(idNotification, body).subscribe(
      () => {
        const indexToRemove = this.notifications.findIndex(notification => {
          return notification.idNotification === idNotification;
        });
        this.notifications.splice(indexToRemove, 1);
      },
      err => {
        this.messageService.add({
          severity: this.dataPeriodEnum.MessageSeverityError,
          summary: this.dataPeriodEnum.MessageSummaryError,
          detail: err.error.description,
          sticky: true
        });
      }
    );
  }

  /**
   * Receives a string date and formats it depending on the predefined language selected by the user.
   * @param date - The date to format.
   * @returns string with the date formatted.
   */
  formatLocalizedDate(date: string): string {
    let currentUser = null;
    currentUser = this.authService.getCurrentUser();
    let localizedDate = moment(date).format('DD/MM/YYYY HH:mm');
    if (currentUser) {
      switch (currentUser.predLanguage) {
        case 'en_gb':
          localizedDate = moment(date).format('YYYY-MM-DD HH:mm');
          break;
        case 'de_de':
          localizedDate = moment(date).format('YYYY-MM-DD HH:mm');
          break;
      }
    }

    return localizedDate;
  }
  
  onMessageReceived(message) {
    const notification = JSON.parse(message.body);
    if (notification.idNotification !== undefined && notification.idNotification !== '' && notification.areaId === this.idArea) {
      //We remove the current notifications that may exist about that document
      if (notification.typeModification === 'delete') {
        const notifications = this.notifications.filter(n => notification.idDocument != n.idDocument);
        this.notifications = notifications;
      }
      this.notifications.push(notification);
      this.notifications.sort((one, two) => (new Date(one.dateCreation) > new Date(two.dateCreation) ? -1 : 1));
      
      if(this.notifications.length > 100) {
        this.notifications = this.notifications.splice(0, 100);
      }
    } else {
      if (notification.success === true) {
        this.messageService.add({ severity: 'success', summary: '', detail: notification.description });
      }
      if (notification.success === false) {
        this.messageService.add({ severity: 'error', summary: '', detail: notification.description, sticky: true });
      }
    }
  }

  navigateToDocument(notification: Notification) {
    this.notificationService.fileName = notification.fileName;
    this.notificationService.dateCreationFile = moment(notification.dateCreation, true).format('YYYY-MM-DD HH:mm');
    const url = notification.inbound ? '/inbound-bucket/' : notification.outbound ? '/outbound-bucket/' : null;
    if (url != null) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([url + notification.idBucket]));
    }
  }
}
