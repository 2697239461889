<header class="navbar">
  <div class="left-side">
    <img *ngIf="!isNotFound" class="root-btn" title="{{ 'shared.menu' | translate }}" src="./assets/images/icons/actions.svg" (click)="changeSideBar()" />
    <a [routerLink]="['/home']"><img [src]="logo" class=logo-{{nameSelectedArea}}/></a>
    <span class="kit">{{ 'sidebar.title' | translate }}</span>
  </div>

  <div class="right-side">
    <!-- LANG -->
    <form [formGroup]="languageForm" *ngIf="languageForm">
      <p-select [options]="languages" formControlName="languageSelector" (onChange)="changeLanguage($event.value)">
        <ng-template let-item pTemplate="selectedItem">
          <span class="selectedItem__label">{{ item.label | translate }}</span>
        </ng-template>
        <ng-template let-lang pTemplate="item">{{ lang.label | translate }}</ng-template>
      </p-select>
    </form>
    <!-- AREA -->
    <p-select
      *ngIf="!isNotFound"
      class="area-selector"
      [options]="areas"
      name="area"
      [(ngModel)]="selectedArea"
      optionLabel="name"
      optionValue="idArea"
      (onChange)="changeArea($event.value)"
    ></p-select>
    <!-- USER -->
    <div class="profile-user" *ngIf="!isNotFound">
      <img class="user-img" title="{{ 'shared.user' | translate }}" src="./assets/images/icons/user.svg" />
      <span class="user-name">{{ (user$ | async)?.name }}</span>
    </div>

    <app-notification *ngIf="!isNotFound" #appNotification></app-notification>
    <app-logout></app-logout>
  </div>
</header>
