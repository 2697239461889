import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FolderSidebarModule } from '@organisms/folder-sidebar/folder-sidebar.module';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { DataService } from '@shared/services/data.service';
import { HttpRequestInterceptor } from '@shared/services/http-request-interceptor';
import { environment } from 'environments/environment';
import { ConfirmationService, FilterService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DatePicker } from 'primeng/datepicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { SelectModule } from 'primeng/select';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OauthModule } from './shared/components/oauth/oauth.module';
import { SharedModule } from './shared/shared.module';
import { PrimeNG } from 'primeng/config';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Material from '@primeng/themes/material';
@NgModule({ declarations: [AppComponent, LayoutComponent],
    bootstrap: [AppComponent],
    imports: [
        ConfirmDialogModule,
        TableModule,
        ToastModule,
        DatePicker,
        SliderModule,
        MultiSelectModule,
        ContextMenuModule,
        DialogModule,
        ButtonModule,
        SelectModule,
        ProgressBarModule,
        InputTextModule,
        FileUploadModule,
        RippleModule,
        MessageModule,
        ProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        DynamicDialogModule,
        BrowserAnimationsModule,
        SharedModule,
        OauthModule,
        FolderSidebarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })], providers: [
        FilterService,
        MessageService,
        PrimeNG,
        ConfirmationService,
        DataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        provideAnimationsAsync(),
        providePrimeNG({
            theme: {
                preset: Material,
                options: {
                    darkModeSelector: false
                }
            }
        }),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?v=' + environment.appVersion + (!environment.production ? '&t=' + new Date().getTime() : ''));
}
