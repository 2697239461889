import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { ButtonDirective } from 'primeng/button';
import { FooterComponent } from '../footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [HeaderComponent, ButtonDirective, FooterComponent, TranslateModule]
})
export class PageNotFoundComponent {
  constructor(private router: Router) {}

  goToHome() {
    this.router.navigateByUrl('/home');
  }
}
