<div class="dropdown" [class.dropdown--active]="isActive">
  <a class="badge-notif" title="{{ 'shared.notifications' | translate }}" (click)="toggleDropdown()">
    <span *ngIf="!loading" class="badge badge-danger">{{ notifications.length }}</span>
    <span *ngIf="loading" class="badge badge-danger">
      <i class="pi pi-spin pi-clock"></i>
    </span>
    <span class="pi pi-bell notifications-bell"></span>
  </a>

  <ul id="ul" class="panel-notif dropdown-menu notifications animated fadeIn fast" role="menu" aria-labelledby="dLabel">
    <span
      class="pi pi-calendar notifications-config"
      (click)="modalPeriodNotification.getPeriodNotifications()"
      title="{{ 'notification.notificationPeriod.tooltipPeriod' | translate }}"
    ></span>
    <form *ngIf="notifications.length !== 0">
      <div class="notification-heading text-center">
        <span class="menu-title">{{ 'notification.title' | translate | uppercase }}</span>
        <span (click)="clearAllNotifications()" class="notification-clear underlineHover"> {{ 'notification.clearAll' | translate }}</span>
      </div>
      <div class="separator" *ngIf="!progressing"></div>
      <p-progressBar *ngIf="progressing" mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
      <!-- <perfect-scrollbar [config]="config"> --><p-scrollPanel>
        <div class="notifications-wrapper">
          <a class="content" *ngFor="let notification of notifications">
            <div class="row align-items-center">
              <div class="notification-item">
                <a
                  (click)="navigateToDocument(notification)"
                  [ngClass]="{ isDisabled: notification.typeModification === 'delete' }"
                  [title]="notification.description ? notification.description : ''"
                  ><span class="item-title notificationHover">
                    {{ notification.fileName?.length > 30 ? (notification.fileName | slice : 0 : 30) + '...' : notification.fileName }}
                    {{
                      notification.description != null && notification.description != ''
                        ? notification.description.length > 20
                          ? '- ' + (notification.description | slice : 0 : 43) + '...'
                          : '- ' + notification.description
                        : ''
                    }}
                  </span></a
                >
                <span class="item-subtitle">{{ notification.title }}</span>
                <span class="item-info">{{ notification.typeModification }} {{ formatLocalizedDate(notification.dateCreation) }}</span>
              </div>
              <div>
                <i class="pi pi-trash trash-icon-notification" (click)="clearNotification(notification.idNotification)" title="{{ 'shared.clear_notifications' | translate }}"> </i>
              </div>
            </div>
          </a>
          <li class="divider"></li>
        </div>
      </p-scrollPanel>
      <!-- </perfect-scrollbar> -->
    </form>
    <div *ngIf="notifications.length === 0" class="text-center" style="padding: 1rem">
      <span style="font-size: 1.1rem; color: #212529"
        ><i class="fa fa-smile-o" title="{{ 'shared.without_notifications' | translate }}"></i>&nbsp; {{ 'shared.without_notifications' | translate }}</span
      >
    </div>
  </ul>

  <app-notification-period #modalPeriodNotification></app-notification-period>
</div>
