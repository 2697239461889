import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '@shared/constants/lang.constant';
import { User } from '@shared/types/user.model';
import { getAdfsUrl } from '@shared/utils/url.util';
import { NEVER, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService, private userService: UserService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const access_token = sessionStorage.getItem('access_token');
    if (access_token) {
      const user: User = this.userService.currentUser;
      const lang = user ? user.predLanguage : '';
      const AreaId = this.userService.currentAreaId || '';

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${access_token}`,
          AreaId
        },
        setParams: { lang: lang && lang.length >= 2 ? lang.substring(0, 2) : Languages[0].name.toLocaleLowerCase() }
      });
    } else {
      window.location.href = getAdfsUrl();
      return NEVER;
    }

    return next.handle(request).pipe(
      tap(
        () => {
          // TODO check if necessary
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 401) {
              localStorage.clear();
              sessionStorage.clear();
              window.location.replace(getAdfsUrl());
            }
            if (error.status == 403) {
              this.translate.get('messages.error_message').subscribe((successMessage: string) => {
                Swal.fire(error.status.toString(), successMessage, 'warning');
              });
            }
          }
        }
      )
    );
  }
}
