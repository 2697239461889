<div class="sidebar" [class.sidebar--toggle]="sidebarState | async">
  <a href="/new-file" class="p-button ford-button sidebar__button" [class.sidebar--toggle]="sidebarState | async">
    {{ 'sidebar.uploadButton' | translate }}
  </a>

  <!-- <p-button
    label="{{ 'sidebar.uploadButton' | translate }}"
    routerLink="/new-file"
    icon="fa fa-plus-circle"
    class="ford-button sidebar__button"
    [class.sidebar--toggle]="sidebarState | async"
  ></p-button> -->
  <p-panelMenu [model]="items$ | async" [class.sidebar--toggle]="sidebarState | async"></p-panelMenu>
</div>
