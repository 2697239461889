import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
// import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ButtonModule } from 'primeng/button';
import { DatePicker } from 'primeng/datepicker';
import { DialogModule } from 'primeng/dialog';
import { SelectModule } from 'primeng/select';
import { ProgressBarModule } from 'primeng/progressbar';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoutComponent } from './components/logout/logout.component';

import { NotificationComponent } from './components/notification/notification.component';
import { PipesModule } from './pipes/pipes.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NotificationPeriodComponent } from './components/notification/notification-period/notification-period.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };

@NgModule({
    exports: [TranslateModule, HeaderComponent, NotificationComponent, FooterComponent, NotificationPeriodComponent],
    imports: [
        TranslateModule,
        FormsModule,
        CommonModule,
        RouterModule,
        ScrollPanelModule,
        SelectModule,
        ProgressBarModule,
        ButtonModule,
        DialogModule,
        DatePicker,
        ReactiveFormsModule,
        PipesModule,
        ProgressSpinnerModule,
        HeaderComponent, NotificationComponent, LogoutComponent, FooterComponent, PageNotFoundComponent, NotificationPeriodComponent
    ],
    providers: []
})
export class SharedModule {}
