import { Component } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css'],
    imports: [TranslateModule]
})
export class LogoutComponent {
  constructor(private auth: AuthService) {}
  logout() {
    this.auth.logout();
  }
}
