import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FiltersNotificationPeriod } from '@shared/types/filters.type';
import { NotificationPeriodRequest } from '@shared/types/notification-period.type';
import { formatDateToUTC } from '@shared/utils/utils';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { NotificationPeriodService } from '../../../services/notification-period.service';
import { ModalPeriodFieldsEnum, maxDateCharge, minDateCharge } from '../notification.const';
import { Dialog } from 'primeng/dialog';
import { NgClass, NgFor } from '@angular/common';
import { DatePicker } from 'primeng/datepicker';
import { ButtonDirective } from 'primeng/button';
import { ProgressSpinner } from 'primeng/progressspinner';

@Component({
    selector: 'app-notification-period',
    templateUrl: './notification-period.component.html',
    styleUrls: ['./notification-period.component.css'],
    imports: [Dialog, FormsModule, ReactiveFormsModule, NgClass, DatePicker, NgFor, ButtonDirective, ProgressSpinner, TranslateModule],
})
export class NotificationPeriodComponent implements OnInit {
  @ViewChild('calendarFrom', { static: false }) calendarFrom: Calendar;
  @ViewChild('calendarTo', { static: false }) calendarTo: Calendar;
  public idArea: string;
  public userId: string;
  public visiblePeriod = false;
  public isLoading = false;
  public isValidDates = false;
  public displayLoadingPeriod = false;
  public isDeletePeriod = true;
  public datePeriodForm: FormGroup;
  public dataPeriodEnum = ModalPeriodFieldsEnum;
  public minDate = minDateCharge;
  public maxDate = maxDateCharge;
  public messagesDate: string[] = [];
  public dateFormat = ModalPeriodFieldsEnum.DateFormat;
  constructor(
    private notificationPeriodService: NotificationPeriodService,
    private messageService: MessageService,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.setForm();
  }

  private setForm() {
    this.datePeriodForm = this.formBuilder.group({
      valueDateFrom: [this.dataPeriodEnum.EmptyValue],
      valueDateTo: [this.dataPeriodEnum.EmptyValue]
    });
  }

  private getDataLocalStorage(): void {
    this.idArea = JSON.parse(localStorage.getItem(this.dataPeriodEnum.ItemAreaLocalStorage)).idArea;
    this.userId = JSON.parse(localStorage.getItem(this.dataPeriodEnum.ItemUserLocalStorage)).idUser;
  }

  public getPeriodNotifications() {
    this.getDataLocalStorage();
    this.setForm();
    this.isLoading = true;
    this.displayLoadingPeriod = true;
    const filtersNotificationPeriod: FiltersNotificationPeriod = {
      areaId: this.idArea
    };
    this.notificationPeriodService.getPeriodNotifications(filtersNotificationPeriod)
    .subscribe({
      next: (response) => {
        this.datePeriodForm.patchValue({
          valueDateFrom: response.fromDate ? new Date(response.fromDate) : null,
          valueDateTo: response.toDate ? new Date(response.toDate) : null
        });
        this.isLoading = false;
        this.displayLoadingPeriod = false;
        this.isDeletePeriod = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.displayLoadingPeriod = false;
        this.isDeletePeriod = true;
        if (error.status !== 404) {
          this.getMessage(
            this.dataPeriodEnum.MessageSeverityError,
            this.dataPeriodEnum.MessageSummaryError,
            'notification.notificationPeriod.modalPeriod.errorMessages.errorNotificationService'
          );
        }
      }
    });
    this.visiblePeriod = true;
  }

  public clearDates() {
    this.isDeletePeriod = true;
    this.datePeriodForm.reset();
    this.formDatesDirty();
    this.clearMessages();
  }

  private formDatesDirty() {
    this.datePeriodForm.get(this.dataPeriodEnum.ValueDateFrom).markAsDirty();
    this.datePeriodForm.get(this.dataPeriodEnum.ValueDateTo).markAsDirty();
  }

  private formDatesPristine() {
    this.datePeriodForm.get(this.dataPeriodEnum.ValueDateFrom).markAsPristine();
    this.datePeriodForm.get(this.dataPeriodEnum.ValueDateTo).markAsPristine();
  }

  public onHide() {
    this.clearDates();
    this.visiblePeriod = false;
  }

  public onSelectedFromToDate() {
    this.isDeletePeriod = false;
  }
  public verifyToSend() {
    this.getDataLocalStorage();
    if (this.datePeriodForm.valid) {
      this.clearMessages();
      if (this.isDeletePeriod) {
        this.deleteUserPeriod();
      } else {
        this.verifyDates(this.calendarFrom.value, this.calendarTo.value);
        if (this.messagesDate.length === 0) {
          this.isValidDates = false;
          this.displayLoadingPeriod = true;
          this.datePeriodForm.markAsPristine();
          this.saveUserPeriod();
        } else {
          this.isValidDates = true;
        }
      }
    }
  }

  private saveUserPeriod() {
    const filtersNotificationPeriod: FiltersNotificationPeriod = {
      areaId: this.idArea
    };
    const request: NotificationPeriodRequest = {
      dateFrom: this.datePeriodForm.get(this.dataPeriodEnum.ValueDateFrom).value
        ? formatDateToUTC(this.datePeriodForm.get(this.dataPeriodEnum.ValueDateFrom).value)
        : this.datePeriodForm.get(this.dataPeriodEnum.ValueDateFrom).value,
      dateTo: this.datePeriodForm.get(this.dataPeriodEnum.ValueDateTo).value
        ? formatDateToUTC(this.datePeriodForm.get(this.dataPeriodEnum.ValueDateTo).value)
        : this.datePeriodForm.get(this.dataPeriodEnum.ValueDateTo).value
    };
    this.notificationPeriodService.savePeriodNotifications(filtersNotificationPeriod, request).subscribe(
      () => {
        this.getMessage(this.dataPeriodEnum.MessageSeveritySuccess, this.dataPeriodEnum.MessageSummaryEmpty, 'notification.notificationPeriod.modalPeriod.messageSaved');
        this.onHide();
        this.displayLoadingPeriod = false;
      },
      () => {
        this.getMessage(
          this.dataPeriodEnum.MessageSeverityError,
          this.dataPeriodEnum.MessageSummaryError,
          'notification.notificationPeriod.modalPeriod.errorMessages.errorNotificationService'
        );
        this.displayLoadingPeriod = false;
      }
    );
  }
  private deleteUserPeriod() {
    this.displayLoadingPeriod = true;
    const filtersNotificationPeriod: FiltersNotificationPeriod = {
      areaId: this.idArea,
      userId: this.userId
    };
    this.notificationPeriodService.deletePeriodNotifications(filtersNotificationPeriod).subscribe(
      () => {
        this.messageService.add({
          severity: this.dataPeriodEnum.MessageSeveritySuccess,
          summary: this.dataPeriodEnum.MessageSummaryEmpty,
          detail: this.translate.instant('notification.notificationPeriod.modalPeriod.messageDeleted')
        });
        this.onHide();
        this.displayLoadingPeriod = false;
      },
      () => {
        this.formDatesPristine();
        this.getMessage(
          this.dataPeriodEnum.MessageSeverityError,
          this.dataPeriodEnum.MessageSummaryError,
          'notification.notificationPeriod.modalPeriod.errorMessages.errorNotificationService'
        );
        this.displayLoadingPeriod = false;
      }
    );
  }

  private verifyDates(fromDate: Date | null, toDate: Date | null): void {
    if (fromDate > toDate && toDate) {
      this.messagesDate = [...this.messagesDate, this.translate.instant('notification.notificationPeriod.modalPeriod.errorMessages.fromGraterTo')];
    }

    if (!fromDate && toDate) {
      this.messagesDate = [...this.messagesDate, this.translate.instant('notification.notificationPeriod.modalPeriod.errorMessages.fromNotEmpty')];
    }
  }

  private clearMessages() {
    this.messagesDate.length = 0;
    this.isValidDates = true;
  }

  public clearDateFromSelected() {
    this.calendarFrom.value = this.dataPeriodEnum.EmptyValue;
    if (this.calendarTo.value == null) {
      this.calendarTo.value = this.dataPeriodEnum.EmptyValue;
    }
    if (this.calendarFrom.value === this.dataPeriodEnum.EmptyValue && this.calendarTo.value === this.dataPeriodEnum.EmptyValue) {
      this.isDeletePeriod = true;
    }
  }

  public clearDateToSelected() {
    this.calendarTo.value = this.dataPeriodEnum.EmptyValue;
    if (this.calendarFrom.value === this.dataPeriodEnum.EmptyValue && this.calendarTo.value === this.dataPeriodEnum.EmptyValue) {
      this.isDeletePeriod = true;
    }
  }

  private getMessage(severityMessage: string, summaryMessage: string, detailMessage: string) {
    this.messageService.add({
      severity: severityMessage,
      summary: summaryMessage,
      detail: this.translate.instant(detailMessage)
    });
  }
}