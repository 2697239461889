<div class="container">
  <app-header></app-header>

  <div class="wrapper">
    <folder-sidebar></folder-sidebar>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <p-dialog  [showHeader]="false" [resizable]="false" [modal]="true" class="text-center loading-modal">
    <div id="loading-content">
      <p-progress-spinner></p-progress-spinner>
    </div>
  </p-dialog>
</div>
