import { Injectable } from '@angular/core';
import { NotificationComponent } from '@shared/components/notification/notification.component';
import { Client, Message } from '@stomp/stompjs';
import { environment } from 'environments/environment';
import SockJS from 'sockjs-client';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private client: Client;
  private topic = '/users/queue/messages';
  private readonly url: string = environment.baseUrlWithoutApiPrefix + `/ws`;
  private reconnectAttempts: number = 0;
  private accessToken = sessionStorage.getItem('encodedAccessToken');
  private parentComponent: NotificationComponent; // Reference to parent component

  constructor() {
    console.log("Creating websocket service")
    this.client = new Client({
        webSocketFactory: () => new SockJS(this.url),
        connectHeaders: {
          Authorization: `Bearer ${this.accessToken}`
        },
        onConnect: () => {
          this.reconnectAttempts = 0;
          console.log('Connected to WebSocket');
          // this.sendMessage('/app/hello', 'Hello, STOMP');
          this.subscribeToMessages();
        },
        onStompError: (frame) => {
          console.error('Broker reported error: ' + frame.headers['message']);
          console.error('Additional details: ' + frame.body);
        },
        onWebSocketClose: () => {
          console.log('WebSocket connection closed');
          this.handleReconnect();
        },
      });

  }

 
  setParentComponent(component: NotificationComponent): void {
    this.parentComponent = component;
  }

  connect(){
    console.log("connecting");
    this.client.activate();
  }

  private subscribeToMessages(): void {
    console.log("Subscribing")
    this.client.subscribe(this.topic, (message: Message) => {
      console.log('Message received: ', message.body);
      if (this.parentComponent && typeof this.parentComponent.onMessageReceived === 'function') {
        this.parentComponent.onMessageReceived(message);
      }
    });
  }

  private handleReconnect(): void {
      console.log(`Attempting to reconnect... (${this.reconnectAttempts + 1})`);
      this.reconnectAttempts++;
      this.client.deactivate();
      setTimeout(() => this.client.activate(), 3000);
  }

  public sendMessage(destination: string, message: string): void {
    console.log('Sending handshake message: ', message);
    if (this.client.connected) {
      this.client.publish({ destination, body: message });
    } else {
      console.error('Cannot send message, socket is not connected.');
    }
  }
}
