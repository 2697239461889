import moment from 'moment/moment';

export const minDateCharge = moment().startOf('day').toDate();
export const maxDateCharge = moment()
  .add(minDateCharge.getDay() + 90, 'days')
  .toDate();

export enum ModalPeriodFieldsEnum {
  DateFormat = 'dd/mm/yy',
  ValueDateFrom = 'valueDateFrom',
  ValueDateTo = 'valueDateTo',
  CalendarFrom = 'calendarFrom',
  CalendarTo = 'calendarTo',
  MessageSeveritySuccess = 'success',
  MessageSeverityError = 'error',
  MessageSummaryEmpty = '',
  EmptyValue = '',
  MessageSummaryError = 'Error',
  ItemAreaLocalStorage = 'area',
  ItemUserLocalStorage = 'user'
}
