import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdleService } from '@shared/services/idle.service';
import { PrimeNG } from 'primeng/config';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService, public primeNG: PrimeNG, private injector: Injector) {
    this.injector.get(IdleService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.translate.stream('primeng').subscribe(data => {
        this.primeNG.setTranslation(data);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
