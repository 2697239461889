<p-dialog header="{{ 'notification.notificationPeriod.modalPeriod.title' | translate }}" [(visible)]="visiblePeriod" [modal]="true" [style]="{ width: '54vw' }" (onHide)="onHide()">
  <div class="formContainer">
    <form [formGroup]="datePeriodForm">
      <div class="p-fluid grid bodyForm" [ngClass]="isLoading ? 'none-select' : ''">
        <div class="p-field col-6 p-md-6 fromDate label-datepicker">
          <label>{{ 'notification.notificationPeriod.modalPeriod.fromDate' | translate }}</label>
          <p-datepicker
            #calendarFrom
            readonlyInput="true"
            id="fromDate"
            showButtonBar="true"
            (onClearClick)="clearDateFromSelected()"
            [showIcon]="true"
            [readonlyInput]="true"
            [minDate]="displayLoadingPeriod ? null : minDate"
            [maxDate]="maxDate"
            [dateFormat]="dateFormat"
            formControlName="valueDateFrom"
            (onSelect)="onSelectedFromToDate()"
            [placeholder]="
              isLoading
                ? ('notification.notificationPeriod.modalPeriod.placeHolderLoading' | translate)
                : ('notification.notificationPeriod.modalPeriod.placeHolderSelect' | translate)
            "
            [icon]="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-calendar'"
            appendTo="body"
          ></p-datepicker>
        </div>
        <div class="p-field col-6 p-md-6 toDate">
          <label>{{ 'notification.notificationPeriod.modalPeriod.toDate' | translate }}</label>
          <p-datepicker
            #calendarTo
            id="toDate"
            showButtonBar="true"
            (onClearClick)="clearDateToSelected()"
            [showIcon]="true"
            [readonlyInput]="true"
            [minDate]="displayLoadingPeriod ? null : minDate"
            [maxDate]="maxDate"
            [dateFormat]="dateFormat"
            formControlName="valueDateTo"
            (onSelect)="onSelectedFromToDate()"
            [placeholder]="
            isLoading
              ? ('notification.notificationPeriod.modalPeriod.placeHolderLoading' | translate)
              : ('notification.notificationPeriod.modalPeriod.placeHolderSelect' | translate)
          "
            appendTo="body"
          >
          </p-datepicker>
        </div>
      </div>
      <div class="body-messages">
        <div class="clearDates">
          <span class="notification-clear" (click)="clearDates()" [ngClass]="isDeletePeriod ? 'none-select' : ''">{{
            'notification.notificationPeriod.modalPeriod.clearDates' | translate
          }}</span>
        </div>
        <div [ngClass]="!isValidDates ? 'valid-dates' : ''">
          <span *ngFor="let msg of messagesDate" class="ui-state-error">{{ msg }}</span>
        </div>
      </div>
      <div class="bodyFooter">
        <button
          pButton
          icon="pi pi-check"
          label="{{ 'notification.notificationPeriod.modalPeriod.buttonAccept' | translate }}"
          [disabled]="!datePeriodForm.dirty"
          (click)="verifyToSend()"
          class="p-button-text ford-button"
        ></button>
        <button
          pButton
          icon="pi pi-times"
          label="{{ 'notification.notificationPeriod.modalPeriod.buttonCancel' | translate }}"
          (click)="onHide()"
          class="p-button-text ford-button"
        ></button>
      </div>
    </form>
  </div>
</p-dialog>

<p-dialog 
  [(visible)]="displayLoadingPeriod" 
  [showHeader]="false" 
  [resizable]="false" 
  [modal]="true" 
  [focusOnShow]="false" 
  class="text-center loading-modal"
>
  <div id="loading-content">
    <p-progress-spinner
      styleClass="custom-spinner"
    ></p-progress-spinner>
  </div>
</p-dialog>
