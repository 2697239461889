import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BucketEntities, UsersEntities } from '@shared/constants/export.constant';
import { BucketService } from '@shared/services/bucket.service';
import { EntitiesService } from '@shared/services/entities.service';
import { ExportService } from '@shared/services/export.service';
import { SidebarService } from '@shared/services/sidebar.service';
import { UserService } from '@shared/services/user.service';
import { Bucket } from '@shared/types/bucket.type';
import { Entity } from '@shared/types/entity.type';
import { DataReq } from '@shared/types/export.type';
import { format_YYYYMMDD } from '@shared/utils/utils';
import moment from 'moment';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { PanelMenu } from 'primeng/panelmenu';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'folder-sidebar',
    templateUrl: './folder-sidebar.component.html',
    styleUrls: ['./folder-sidebar.component.scss'],
    imports: [PanelMenu, AsyncPipe, TranslateModule]
})
export class FolderSidebarComponent implements OnInit {
  sidebarState:any;
  items$: Observable<MenuItem[]>;

  private isOutboundVisible: boolean;
  user$: any;
  private isAdmin: boolean;
  private isSuperAdmin: boolean;
  private expanded = false;
  private loading: boolean;

  constructor(
    private sidebarService: SidebarService,
    private userService: UserService,
    private bucketService: BucketService,
    private entityService: EntitiesService,
    private translate: TranslateService,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.sidebarState = this.sidebarService.sidebarState;
    this.user$ = this.userService.user$;
    this.bucketService.hasAccessToOutboundBuckets();

    this.bucketService.outboundAccess.subscribe(outboundAccess => {
      this.isOutboundVisible = outboundAccess;
      this.items$ = of(this.getMenu());
    });

    this.user$.subscribe(user => {
      if (user) {
        this.isAdmin = this.userService.isAdminInCurrentArea;
        this.isSuperAdmin = this.userService.isSuperAdminInCurrentArea;
      }
    });

    this.translate.onLangChange.subscribe(() => (this.items$ = of(this.getMenu())));
  }

  private async exportExcel() {
    this.loading = true;
    this.expanded = true;
    this.items$ = of(this.getMenu());

    let entityUsers = [];
    let bucketsEntities = [];

    if (this.isAdmin && !this.isSuperAdmin) {
      const result: [Entity[], Bucket[]] = await Promise.all([this.entityService.getEntitiesLocalAdmin(), this.bucketService.getAllBucketsAdminReport()]);
      const entities: Entity[] = result[0] || [];
      const buckets: Bucket[] = result[1] || [];
      entityUsers = this.getEntityUsersLocalAdmin(entities);
      bucketsEntities = this.getBucketsEntitiesLocalAdmin(buckets);
    }

    if (this.isSuperAdmin) {
      const result: [Entity[], Bucket[]] = await Promise.all([this.entityService.getAllEntities(), this.bucketService.getAllBucketsAdminReport()]);
      const entities: Entity[] = result[0] || [];
      const buckets: Bucket[] = result[1] || [];
      entityUsers = this.getEntityUsersSuperAdmin(entities);
      bucketsEntities = this.getBucketsEntitiesSuperAdmin(buckets);
    }

    const areaName = [...this.userService._area.value.name][0].toUpperCase() + [...this.userService._area.value.name].slice(1).join('');

    const dataReq: DataReq[] = [
      {
        data: entityUsers,
        title: `${this.translate.instant('admin.report.usersEntities')} ${areaName}`,
        headers: UsersEntities
      },
      {
        data: bucketsEntities,
        title: `${this.translate.instant('admin.report.bucketsEntities')} ${areaName}`,
        headers: BucketEntities
      }
    ];

    this.exportService.exportExcel(dataReq, `${this.translate.instant('sidebar.adminReport')} ${format_YYYYMMDD(new Date(), '-')}`).then(() => {
      this.loading = false;
      this.expanded = true;
      this.items$ = of(this.getMenu());
    });
  }

  private getBucketsEntitiesLocalAdmin(buckets: Bucket[]): any[] {
    const bucketsEntities = [];
    buckets.forEach(bucket => {
      if (bucket.entities) {
        bucket.entities.forEach(bucketEntities => {
          const existFather = bucketsEntities.find(bucket => bucket.bucketEntityId == bucketEntities.idBucketEntity);
          if (!existFather) {
            bucketsEntities.push({
              entityCode: bucketEntities.entityCode,
              entity: bucketEntities.entityName,
              bucket: bucket.name,
              altKey: bucket.alternativeKey,
              upload: `${this.translate.instant('admin.report.' + !!bucketEntities.pUpload)}`,
              download: `${this.translate.instant('admin.report.' + !!bucketEntities.pDownload)}`,
              reportable: `${this.translate.instant('admin.report.' + !!bucketEntities.reportable)}`,
              move: `${this.translate.instant('admin.report.' + !!bucketEntities.pMove)}`,
              edit: `${this.translate.instant('admin.report.' + !!bucketEntities.pEdit)}`,
              delete: `${this.translate.instant('admin.report.' + !!bucketEntities.pDelete)}`,
              access: `${this.translate.instant('admin.report.' + !!bucketEntities.pAccess)}`,
              version: `${this.translate.instant('admin.report.' + !!bucketEntities.pVersion)}`,
              userModificationReport: bucketEntities.userModificationReport,
              dateModificationReport: moment(bucketEntities.dateModificationReport).format('DD-MM-YYYY HH:mm'),
              //
              entityId: bucketEntities.idEntity,
              entityParentId: null,
              entityParentName: '',
              bucketId: bucket.idBucket
            });
          }
        });
      }
    });
    return bucketsEntities;
  }

  private getBucketsEntitiesSuperAdmin(buckets: Bucket[]): any[] {
    const bucketsEntities = [];
    buckets.forEach(bucket => {
      if (bucket.entities) {
        bucket.entities.forEach(bucketEntities => {
          bucketsEntities.push({
            entityCode: bucketEntities.entityCode,
            entity: bucketEntities.entityName,
            bucket: bucket.name,
            altKey: bucket.alternativeKey,
            upload: `${this.translate.instant('admin.report.' + !!bucketEntities.pUpload)}`,
            download: `${this.translate.instant('admin.report.' + !!bucketEntities.pDownload)}`,
            reportable: `${this.translate.instant('admin.report.' + !!bucketEntities.reportable)}`,
            move: `${this.translate.instant('admin.report.' + !!bucketEntities.pMove)}`,
            edit: `${this.translate.instant('admin.report.' + !!bucketEntities.pEdit)}`,
            delete: `${this.translate.instant('admin.report.' + !!bucketEntities.pDelete)}`,
            access: `${this.translate.instant('admin.report.' + !!bucketEntities.pAccess)}`,
            version: `${this.translate.instant('admin.report.' + !!bucketEntities.pVersion)}`,
            userModificationReport: bucketEntities.userModificationReport,
            dateModificationReport: moment(bucketEntities.dateModificationReport).format('DD-MM-YYYY HH:mm'),
            //
            entityId: bucketEntities.idEntity,
            entityParentId: null,
            entityParentName: '',
            bucketId: bucket.idBucket
          });
        });
      }
    });
    return bucketsEntities;
  }

  private getEntityUsersLocalAdmin(entities: Entity[]): any[] {
    const entityUsers = [];
    entities.forEach(entity => {
      const parent = entities.find(e => e.idEntity === entity.idParent);
      if (entity.userEntities) {
        entity.userEntities.forEach(user => {
          let entObj: any = {
            entidad: entity.name,
            entityCode: entity.entityCode,
            user: user.user ? user.user.idLogin : null,
            name: user.user ? user.user.name : '',
            email: user.user ? user.user.email : '',
            isAdmin: `${this.translate.instant('admin.report.' + !!user.isAdmin)}`,
            dateModificationReport: user.dateModificationReport,
            userModificationReport: user.userModificationReport
          };
          if (entity.idParent) {
            entObj = { ...entObj, parentEntity: parent ? parent.name : null, parentEntityCode: parent ? parent.entityCode : null };
          } else {
            this.entityService.getEntitySimpleResponse(entity.idParent).subscribe(parent => {
              entObj = { ...entObj, parentEntity: parent.name, parentEntityCode: parent.entityCode };
            });
          }
          entityUsers.push(entObj);
        });
      } else {
        let entObj: any = {
          entidad: entity.name,
          entityCode: entity.entityCode,
          dateModificationReport: entity.dateModificationReport,
          userModificationReport: entity.userModificationReport
        };
        if (entity.idParent) {
          entObj = { ...entObj, parentEntity: parent ? parent.name : null, parentEntityCode: parent ? parent.entityCode : null };
        } else {
          this.entityService.getEntitySimpleResponse(entity.idParent).subscribe(parent => {
            entObj = { ...entObj, parentEntity: parent.name, parentEntityCode: parent.entityCode };
          });
        }
        entityUsers.push(entObj);
      }
    });
    return entityUsers;
  }

  private getEntityUsersSuperAdmin(entities: Entity[]): any[] {
    const entityUsers = [];
    entities.forEach(entity => {
      const parent = entities.find(e => e.idEntity === entity.idParent);
      if (entity.userEntities) {
        entity.userEntities.forEach(user => {
          let entObj: any = {
            entidad: entity.name,
            entityCode: entity.entityCode,
            user: user.user ? user.user.idLogin : null,
            name: user.user ? user.user.name : '',
            email: user.user ? user.user.email : '',
            isAdmin: `${this.translate.instant('admin.report.' + !!user.isAdmin)}`,
            dateModificationReport: user.dateModificationReport,
            userModificationReport: user.userModificationReport
          };
          if (entity.idParent) {
            entObj = { ...entObj, parentEntity: parent ? parent.name : null, parentEntityCode: parent ? parent.entityCode : null };
          }
          entityUsers.push(entObj);
        });
      } else {
        let entObj: any = {
          entidad: entity.name,
          entityCode: entity.entityCode,
          dateModificationReport: entity.dateModificationReport,
          userModificationReport: entity.userModificationReport
        };
        if (entity.idParent) {
          entObj = { ...entObj, parentEntity: parent ? parent.name : null, parentEntityCode: parent ? parent.entityCode : null };
        }
        entityUsers.push(entObj);
      }
    });
    return entityUsers;
  }

  private getMenu(): MenuItem[] {
    return [
      {
        label: this.translate.instant('sidebar.home'),
        icon: 'fa fa-home',
        routerLink: '/home'
      },
      {
        label: this.translate.instant('sidebar.inboundBuckets'),
        icon: PrimeIcons.ARROW_CIRCLE_DOWN,
        routerLink: '/inbound-bucket'
      },
      {
        label: this.translate.instant('sidebar.outboundBuckets'),
        icon: PrimeIcons.ARROW_CIRCLE_UP,
        routerLink: '/outbound-bucket',
        visible: this.isOutboundVisible
      },
      {
        label: this.translate.instant('sidebar.document-report'),
        icon: PrimeIcons.CHART_BAR,
        routerLink: '/document-report'
      },
      {
        label: this.translate.instant('sidebar.adminConfig'),
        visible: this.isAdmin || this.isSuperAdmin,
        expanded: this.expanded,
        items: [
          {
            label: this.translate.instant('sidebar.adminEntities'),
            icon: PrimeIcons.PLUS,
            routerLink: '/configuration/entities'
          },
          {
            label: this.translate.instant('sidebar.adminUsers'),
            icon: PrimeIcons.USER_PLUS,
            routerLink: '/configuration/users'
          },
          {
            label: this.translate.instant('sidebar.adminBuckets'),
            icon: PrimeIcons.FOLDER_OPEN,
            routerLink: '/configuration/buckets',
            visible: this.isSuperAdmin
          },
          {
            label: this.translate.instant('sidebar.adminLabels'),
            icon: PrimeIcons.TAGS,
            routerLink: '/configuration/labels',
            visible: this.isSuperAdmin
          },
          {
            label: this.translate.instant('sidebar.adminReport'),
            icon: this.loading ? 'pointer pi pi-spin pi-spinner' : PrimeIcons.FILE_EXCEL,
            disabled: this.loading,
            command: () => this.exportExcel()
          },
          {
            label: this.translate.instant('sidebar.adminBulkLoad'),
            icon: PrimeIcons.UPLOAD,
            routerLink: '/configuration/bulkload',
            visible: this.isSuperAdmin
          }
        ]
      }
    ];
  }
}
